<script>
import {vueAppMixin} from "@/mixins/vueAppMixin";
import FileTree from "@/components/vessel/documents/components/FileTree.vue";
import ImageSlider from "@/components/common/ImageSlider.vue";
import ImageSliderMixin from "@/mixins/ImageSliderMixin";
import BaseModal from "@/components/common/BaseModal.vue";
import {VesselService} from "@/services/VesselService";
import AppLoading from "@/components/elements/AppLoading.vue";
import {API_URL, TOKEN_NAME, UPLOAD_VESSEL_SMS_RECORD, UPLOAD_VESSEL_VISITATION} from "@/services/ConstantService";
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
import vueFilePond from 'vue-filepond'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

// Import styles
import 'filepond/dist/filepond.min.css';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import {AlertService} from "@/services/AlertService";

// Create FilePond component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

export default {
  name: 'SmsRecord',
  components: {AppLoading, ImageSlider, FileTree, BaseModal, FilePond, VueImageLightboxCarousel, DatePicker},
  mixins: [vueAppMixin,ImageSliderMixin],
  data() {
    return {
      password: null,
      openImage: [],
      showLightbox: false,
      isUploadSuccess: false,
      isLoading: true,
      uploadModal: false,
      newFolderModal: false,
      deleteModal: false,
      files: [],
      newFolderName: null,
      selected: [],
      serverOptions: {
        process: {
          url: API_URL + UPLOAD_VESSEL_SMS_RECORD.replace('{vessel_id}', this.$route.params.id),
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('kidsToken'),
          },
          ondata: (formData) => {
            formData.append('folder_id', this.selected);
            return formData;
          }
        }
      }
    };
  },
  methods: {
    reset() {
      this.enableAll(this.files)
      this.selected = []
    },
    closeDeleteModal(){
      this.deleteModal = false
      this.password = null
    },
    async fetchData() {
      this.isLoading = true
      this.files = await VesselService.getSmsRecord(this.$route.params.id)
      this.isLoading = false
    },
    async handleDelete(){
      try {
        await VesselService.deleteFile(
          {
            vessel_id: this.$route.params.id,
            file_ids: this.selected,
            password: this.password
          }
        )
        this.deleteModal = false
        this.DeleteChildrenToNodes(this.selected)
        this.password = null
        AlertService.successAlert('File Deleted Successfully', 'FILE DELETION')
        this.reset()
      } catch (e) {
        AlertService.errorAlert(e.message, 'FILE DELETION')
      }

    },
    handleCloseUploadModal(){
      this.uploadModal = false
      this.isUploadSuccess = false
    },
    handleUploadedFile(error, file){

      this.addChildToNode(this.selected[0], JSON.parse(file.serverId).file);
    },
    handleUploadSuccess(){
      this.isUploadSuccess = true
      this.$refs.success.togglePlayPause()
      this.reset()
    },
    handleUpload(){
      this.$refs.pond.processFiles()
    },
    async handleNewFolder() {
      const params = {
        vessel_id: this.$route.params.id,
        name: this.newFolderName
      }
      try {
        swal.fire({title: 'Please wait'});
        swal.showLoading();

        await VesselService.createSMSFolder(params)
      } catch (e) {

      } finally {
        swal.close()
      }

      this.newFolderName = null
      this.newFolderModal = false
      this.selected = []

      this.files = await VesselService.getSmsRecord(this.$route.params.id)

    },
    async handleFileClicked(file){
      window.open(file.url, '_blank')
    },
    handleSelect(file){

      if (this.selected.includes(file.id)) {
        this.selected = this.selected.filter(item => item !== file.id)
      } else {
        this.selected.push(file.id)
      }
      const parentId = this.getParentId(file.id, this.files)

      if(parentId) {
        const parent = this.getItemById(parentId, this.files);


        const allChildrenSelected = parent.children.every(child => !this.selected.includes(child.id));
        if(allChildrenSelected){
          this.toggleById(parentId, false)
          this.selected = this.selected.filter(item => item !== parentId)
        } else {
          this.toggleById(parentId, true)
          this.selected = this.selected.filter(item => item !== parentId)
        }

      }
    },
    getItemById(id, items) {
      for (let item of items) {
        if (item.id === id) {
          return item; // Return the found item
        } else if (item.children && item.children.length) {
          const result = this.getItemById(id, item.children); // Recursively search children
          if (result) return result;
        }
      }
      return null; // Return null if not found
    },
    enableAll(items) {
      items.forEach(item => {
        item.disabled = false; // Set disabled to true for current item
        item.selected = false;
        if (item.children && item.children.length > 0) {
          this.enableAll(item.children); // Recursively call for children
        }
      });
    },
    toggleById(id, disabled) {
      const findAndDisable = (items = this.files) => {
        for (let item of items) {
          if (item.id === id) {
            if(item.selected && disabled) {
              item.selected = false
            }
            item.disabled = disabled
            return true; // Stop searching once found
          } else if (item.children && item.children.length) {
            if (findAndDisable(item.children)) return true; // Recursively search children
          }
        }
        return false; // Return false if not found
      };

      findAndDisable(this.items); // Start searching from the root items
    },
    getSelectedFile() {
      // Assuming files is your array of files/folders
      return this.files.flatMap(folder =>
        folder.children ? [folder, ...folder.children] : folder
      ).find(file => file.id === this.selected[0]);
    },
    addChildToNode(nodeId, newChild) {
      const findAndAddChild = (nodes) => {
        for (let node of nodes) {
          if (node.id === nodeId) {
            // Add new item to the children
            node.children.push(newChild);
            return true; // Stop when we find the node
          }
          // Recursively check children if they exist
          if (node.children.length > 0) {
            const found = findAndAddChild(node.children);
            if (found) return true; // Stop the recursion once found
          }
        }
        return false; // Continue searching
      };

      // Call the recursive function with the root file structure
      findAndAddChild(this.files);
    },
    DeleteChildrenToNodes(nodeIds) {
      const findAndDeleteChildren = (nodes) => {
        for (let node of nodes) {
          // Check if any child in this node matches any nodeId in the array
          console.log('sya to', node.children)
          if (node.children.some(child => nodeIds.includes(child.id))) {
            node.children = node.children.filter(child => !nodeIds.includes(child.id));
          }

          // Check if this node matches any nodeId in the array
          if (nodeIds.includes(node.id)) {
            this.files = nodes.filter(item => !nodeIds.includes(item.id));
            return true;
          }

          // Recursively check children if they exist
          if (node.children.length > 0) {
            findAndDeleteChildren(node.children); // Recursively search in children
          }
        }
      };

      // Call the recursive function with the root file structure
      findAndDeleteChildren(this.files);
    },
    closeFolderModal(){
      this.newFolderModal = false
      this.newFolderName = null
    },
    getParentId(childId, items, parentId = null) {
      for (let item of items) {
        if (item.id === childId) {
          return parentId;
        }
        if (item.children.length > 0) {
          let result = this.getParentId(childId, item.children, item.id);
          if (result !== null) {
            return result;
          }
        }
      }
      return null;
    }
  },
  async created(){
    this.fetchData()
  },
  computed: {
    hasSelected(){
      return this.selected.length > 0
    },
    shouldShowUploadBtn(){
      const selectedFile = this.getSelectedFile();
      return this.selected.length === 1 && selectedFile && selectedFile.type === 'folder';
    }
  }
}
</script>

<template>
  <div class="p-2">
    <div class="toolbar">
      <button  class="e-btn e-btn-blue force-white-all pr-3" @click="newFolderModal = true">
        <font-awesome-icon icon="folder-plus" style="width: 25px" />
        <small class="font-weight-bold">
          NEW FOLDER
        </small>
      </button>
      <button v-if="shouldShowUploadBtn" class="e-btn e-btn-blue force-white-all pr-3" @click="uploadModal = true">
        <font-awesome-icon icon="upload" style="width: 25px" />
        <small class="font-weight-bold">
          UPLOAD FILE
        </small>
      </button>
      <button v-if="hasSelected" class="e-btn e-btn-red force-white-all pr-3" @click="deleteModal = true">
        <font-awesome-icon icon="trash" style="width: 25px" />
        <small class="font-weight-bold">
          DELETE ({{ selected.length }})
        </small>
      </button>
    </div>
    <app-loading v-if="isLoading"/>
    <div v-else>
      <template v-if="files.length > 0">
        <file-tree
          key="1"
          class="mt-2 "
          :files="files"
          style="padding: 20px;background-color: #f7f7f7;"
          @fileClicked="handleFileClicked"
          @select="handleSelect"
        />
      </template>
      <template v-else>
        <div style="display: flex; justify-content: center; align-items: center; padding: 25px; background-color: #f7f7f7;">
          <p style="font-size: 20px">No files</p>
        </div>
      </template>
    </div>
    <!--    <vue-image-lightbox-carousel-->
    <!--      ref="lightbox"-->
    <!--      @close="showLightbox = false"-->
    <!--      :show="showLightbox"-->
    <!--      :images="openImage"-->
    <!--    />-->
    <image-slider
      :selected-image-index="selectedImageIndex"
      :images="sliderImages"
      :visible="showSlider"
      @hideSlider="resetSlider()"
    />

    <BaseModal :isVisible="uploadModal" @close="uploadModal = false" :close-button="false">
      <template v-slot:title>Upload file</template>
      <template v-slot:body>
        <template v-if="!isUploadSuccess">

          <file-pond id="test" name="file" ref="pond" allow-multiple="true" max-files="5" instantUpload="false" :server="serverOptions"
                     :allow-reorder="true"
                     allow-revert="false"
                     :accepted-file-types="['image/jpeg', 'image/png']"
                     :max-file-size="5000000"
                     allow-process="false"
                     @processfile="handleUploadedFile"
                     @processfiles="handleUploadSuccess"
          />
          <span class="text-danger">*</span>max file 5
        </template>
        <div v-show="isUploadSuccess">
          <lottie-vue-player
            ref="success"
            class="w-full"
            :src="`https://lottie.host/39967dbf-5dd8-47fc-a32f-f9e84dabbe7b/2WFUeDHfZU.json`"
            :autoplay="false"
            :loop="false"
            style="height: 190px"
          ></lottie-vue-player>
          <h3 class="text-success text-center">Uploaded Successfully</h3>
        </div>

      </template>
      <template v-slot:footer>
        <template v-if="!isUploadSuccess">
          <button type="button" class="btn btn-sm btn-secondary" @click="handleCloseUploadModal">Cancel</button>
          <button type="button" class="btn btn-sm btn-primary" @click="handleUpload">Upload</button>
        </template>
        <template v-else>
          <button type="button" class="btn btn-sm btn-primary" @click="handleCloseUploadModal">Done</button>
        </template>
      </template>
    </BaseModal>


    <BaseModal :isVisible="deleteModal" @close="deleteModal = false" :close-button="false">
      <template v-slot:title>DELETION OF {{ selected.length }} FILE(S)/FOLDER(S)</template>
      <template v-slot:body>

        <p style="font-size: 13px; margin-bottom: 0"> This action will permanently delete the selected File(s)/Folder(s).</p>
        <p style="font-size: 13px;"> <b style="color:red">Note: This action cannot be undone.</b> Please enter your password to proceed.</p>
        <!--        <span style="font-size: 12px"><b>Please type your Password to Confirm.</b></span>-->
        <form @submit.prevent="handleDelete">
          <input type="password" v-model="password" class="form-control form-control-sm" placeholder="Enter Password" autocomplete="off">
        </form>
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-sm btn-secondary" @click="closeDeleteModal">Cancel</button>
        <button type="button" class="btn btn-sm btn-primary" @click="handleDelete">Confirm</button>
      </template>
    </BaseModal>

    <BaseModal :isVisible="newFolderModal" @close="closeFolderModal" :close-button="false">
      <template v-slot:title>CREATION OF NEW FOLDER</template>
      <template v-slot:body>
        <form @submit.prevent="handleNewFolder">
<!--          <date-picker class="w-100" id = "date-picker" :lang = 'en' type="date" valueType="format" placeholder="Select Date Range" v-model="newFolderName"></date-picker>-->
<!--          <input type="text" v-model="newFolderName" class="form-control form-control-sm" placeholder="Folder Name" autofocus>-->
          <select class="form-control form-control-sm text-uppercase" id="CountryList" v-model="newFolderName">
            <!--                <option :value="null">—— SELECT COUNTRY ——</option>-->
            <option :value="null">—— SELECT ——</option>
            <option :value="1">SMS-B-01 [Procedures for Risk Assessment]</option>
            <option :value="2">SMS-B-02 [Safety Procedures for Dangerous Works]</option>
          </select>
        </form>
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-sm btn-secondary" @click="closeFolderModal">Cancel</button>
        <button type="button" class="btn btn-acsm btn-primary" @click="handleNewFolder" :disabled="newFolderName === ''">Create</button>
      </template>
    </BaseModal>
  </div>
</template>

<style>
.toolbar {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.filepond--list *, .filepond--list *::before, .filepond--list *::after {
  color: #fff;
}

.filepond--credits {
  display: none;
}
</style>
