import {KeyService} from '@/services/KeyService'
import {VesselService} from '@/services/VesselService'
import {DataService} from '@/services/DataService'

const state = {
  vesselTypeKeys: [],
  vesselKeys: [],
  vesselList: [],
  vessel: {},
  vesselInfo: {},
  vesselManagement : {},
  vesselAccidentIncidentYearlyCounts : [],
  vesselAccidentIncidentYearlyCountsList : [],
  vesselSummaryList : []
}

const mutations = {
  setVesselManagement : (state, vesselManagement) => state.vesselManagement = vesselManagement,
  // eslint-disable-next-line no-return-assign
  setVesselTypeKeys: (state, vesselTypeKeys) => state.vesselTypeKeys = vesselTypeKeys,
  // eslint-disable-next-line no-return-assign
  setVesselKeys: (state, vesselKeys) => state.vesselKeys = vesselKeys,
  // eslint-disable-next-line no-return-assign
  setVesselList: (state, vesselList) => state.vesselList = vesselList,
  // eslint-disable-next-line no-return-assign
  setVessel: (state, vessel) => state.vessel = vessel,

  removeVessel: (state,vesselId)=> {
    let index=state.vesselList.findIndex(vessel=>vessel.id===vesselId);
    if(index >= 0){
      state.vesselList.splice(index,1);
    }
  },

  setVesselInfo: (state, vesselInfo) => state.vesselInfo = vesselInfo,
  setVesselAccidentIncidentYearlyCounts: (state, vesselAccidentIncidentYearlyCounts) => state.vesselAccidentIncidentYearlyCounts = vesselAccidentIncidentYearlyCounts,
  setVesselAccidentIncidentYearlyCountsList: (state, vesselAccidentIncidentYearlyCountsList) => state.vesselAccidentIncidentYearlyCountsList = vesselAccidentIncidentYearlyCountsList,
  setVesselSummaryList: (state, vesselSummaryList) => state.vesselSummaryList = vesselSummaryList
}

const actions = {
  async getDashboardVesselManagement({commit}) {
    const vesselManagement = await VesselService.getDashboardVesselManagement()
    if (vesselManagement) commit('setVesselManagement', vesselManagement)
  },
  async getVesselSummaryList({commit}, params) {
    let vesselSummaryList = await VesselService.getVesselSummaryList(params);
    if (vesselSummaryList) commit('setVesselSummaryList', vesselSummaryList);
  },
  async getVesselTypeKeys({commit}) {
    const vesselTypeKeys = await KeyService.getVesselTypeKeys()
    if (vesselTypeKeys) commit('setVesselTypeKeys', vesselTypeKeys)
  },
  async getVesselKeys({commit}) {
    const vesselKeys = await KeyService.getVesselKeys()
    if (vesselKeys) commit('setVesselKeys', vesselKeys)
  },
  async getVessels({commit}, params) {
    let vesselList = await VesselService.getVesselList(params)
    if (vesselList){
      commit('setVesselList', vesselList.data)
      commit('setPagination',vesselList)
    }
  },
  async getVesselDetail({commit}, vesselId) {
    commit('setVessel', {})
    const vessel = await VesselService.getVesselDetail(vesselId)
    if (vessel) commit('setVessel', vessel)
  },

  async getVesselInfo({ commit }, params) {
    commit('setVesselInfo', {})
    let vesselInfo = await VesselService.getVesselInfo(params);
    if(vesselInfo) commit('setVesselInfo', vesselInfo);
  },

  async updateVessel({commit}, params) {
    const updatedVessel = await VesselService.updateVessel(params);
    if (updatedVessel) commit('setVesselInfo', updatedVessel)
  },

  async deleteVessel({commit},vessel_id){
     const response=await VesselService.deleteVessel(vessel_id);
     if(response.success){
       commit('removeVessel',vessel_id)
       return true;
     }
     return false;
  },

  async getVesselAccidentIncidentYearlyCounts({commit}, params) {
    const accidentIncidentYearlyCounts = await VesselService.getVesselAccidentIncidentYearlyCounts(params);
    if (accidentIncidentYearlyCounts){
      commit('setVesselAccidentIncidentYearlyCounts', accidentIncidentYearlyCounts.data)
      commit('setPagination',accidentIncidentYearlyCounts)
    }
  },

  async getVesselAccidentIncidentYearlyCountsList({commit}, params) {
    const accidentIncidentYearlyCountsLists = await VesselService.getVesselAccidentIncidentListFromYearlyCount(params);
    if (accidentIncidentYearlyCountsLists){
      commit('setVesselAccidentIncidentYearlyCountsList', accidentIncidentYearlyCountsLists.data)
    }

  }
}

const getters = {
  vesselManagement: state => state.vesselManagement,
  vesselTypeKeys: state => state.vesselTypeKeys,
  vesselKeys: state => DataService.capitalizeProperties(state.vesselKeys),
  vesselList: state => DataService.capitalizeProperties(state.vesselList),
  vessel: state => DataService.capitalizeProperties(state.vessel, ['vessel_image']),
  vesselInfo: state => DataService.capitalizeProperties(state.vesselInfo, ['vessel_image']),
  vesselAccidentIncidentYearlyCounts: state => state.vesselAccidentIncidentYearlyCounts,
  vesselAccidentIncidentYearlyCountsList: state => state.vesselAccidentIncidentYearlyCountsList,
  vesselSummaryList: state => state.vesselSummaryList,
}

export default {
  state,
  mutations,
  actions,
  getters
}
