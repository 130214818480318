<script>
import {vueAppMixin} from '@/mixins/vueAppMixin'

export default {
  name: 'VesselDocuments',
  mixins: [vueAppMixin]
}
</script>

<template>
  <div class="mx-2">
    <div class="row no-gutters">
      <div class="col-sm-12">
        <nav class="bg-light">
          <div class="nav nav-tabs nav-fill w-100" id="nav-tab" role="tablist">

              <li class="nav-item">


                <router-link
                  v-if="this.$route.params.id === '424'"
                  :to="{name: 'VesselFiles' }"
                  tag="a"
                  class="nav-link border border-bottom-0"
                  :class="{'active text-white': isActiveRoute('VesselFiles')}"
                  role="tab"
                  data-toggle="list"
                >
                  Files
                </router-link>
                <a href="#" class="nav-link border border-bottom-0 e-bg-blue-gray disabled-link" v-else>Files</a>
              </li>
<!--            <a href="#" class="nav-link border border-bottom-0 e-bg-blue-gray disabled-link">Vessel Visitation</a>-->
              <li class="nav-item">
                <router-link
                  :to="{name: 'VesselVisitation' }"
                  tag="a"
                  class="nav-link border border-bottom-0"
                  :class="{'active text-white b': isActiveRoute('VesselVisitation')}"
                  role="tab"
                  data-toggle="list"
                >
                  Vessel Visitation
                </router-link>
              </li>

              <li class="nav-item">
                <router-link
                  :to="{name: 'SmsRecord' }"
                  tag="a"
                  class="nav-link border border-bottom-0"
                  :class="{'active text-white b': isActiveRoute('SmsRecord')}"
                  role="tab"
                  data-toggle="list"
                >
                  SMS Records
                </router-link>
              </li>
          </div>
        </nav>

        <div class="py-2 px-1 text-left border border-top-0">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.active {
  background-color: #dc3545 !important;
}
.disabled-link:hover {
  cursor: not-allowed;
}
</style>
